import React from 'react';
import styled from 'styled-components';

import Header from './Header';
import Highlights from './Highlights';
import Description from './Description';
import Video from './Video';
import Clients from './Clients';
import Footer from './Footer';

const Wrapper = styled.div`
  text-align: center;
`;

const App = () => {
  return (
    <Wrapper>
      <Header />

      <Highlights />
      <Description />
      <Video />
      <Clients />

      <Footer />
    </Wrapper>
  );
};

export default App;
