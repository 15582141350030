import React from 'react';
import styled from 'styled-components';
import { TrendingUp, Shield, Zap } from 'styled-icons/feather';

const Container = styled.div`
  width: 100%;
  background: #333;
  padding: 2em 0;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  color: #ddd;

  > * {
    width: 100%;
  }
`;

const Headline = styled.div`
  padding-bottom: 3em;

  h2 {
    font-size: 2.5em;
    font-weight: 300;
    margin: 0;
    margin-bottom: 0.5em;
  }

  p {
    font-weight: 200;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  background: #333;
`;

const Item = styled.div`
  width: 20%;

  h3 {
    font-size: 2em;
    font-weight: 300;
    margin: 0;
  }

  p {
    font-weight: 200;
  }
`;

const iconSize = 100;

const Highlights = () => (
  <Container>
    <Wrapper>
      <Headline>
        <h2>The future of MMO technology</h2>
        <p>
          Exploiting the power of the cloud and sophisticated architecture, KAMO offers unparalleled
          performance
        </p>
      </Headline>

      <Content>
        <Item>
          <TrendingUp size={iconSize} />
          <h3>Scalable</h3>
          <p>Kamo utilizes advanced network cluster techniques to scale infinitely in the cloud</p>
        </Item>

        <Item>
          <Shield size={iconSize} />
          <h3>Secure</h3>
          <p>Persistant information is securely stored on SOC and PCI DSS compliant AWS servers</p>
        </Item>

        <Item>
          <Zap size={iconSize} />
          <h3>Speed</h3>
          <p>
            Using battle-tested military grade solutions allows Kamo to offer ludicrously fast
            performance
          </p>
        </Item>
      </Content>
    </Wrapper>
  </Container>
);

export default Highlights;
