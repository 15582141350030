import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @import url("https://fonts.googleapis.com/css?family=Raleway:100,200,300,400");

  body {
    margin: 0;
    font-family: Raleway;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
    background: #000;
  }
`;
