import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  height: 50px;
  text-align: center;
  background: #333;
  text-align: center;
  color: #aaa;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Wrapper>
        <span>Directive Games ©2019</span>
      </Wrapper>
    </FooterContainer>
  );
};

export default Footer;
