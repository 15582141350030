import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

import backgroundImage from './assets/background.jpg';
import logoImage from './assets/logo.png';
import Button from './Button';

const HeaderContainer = styled.header`
  background-color: #282c34;
  background: url(${backgroundImage}) no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;

  > * {
    z-index: 2;
  }
`;

const Overlay = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0px 0px 100px 50px rgba(0, 0, 0, 0.75);
  z-index: 1;
`;

const Title = styled.h1`
  font-size: 4em;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  text-indent: 0.5em;
  font-weight: 100;
  margin: 0;
  margin-top: -2em;
`;

const ButtonContainer = styled.div`
  width: 450px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
`;

const Divider = styled.div`
  width: 50%;
  height: 2px;
  background-color: rgba(200, 200, 200, 0.5);
  margin: 1.2em 0;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 196px;

  @media all and (max-width: 768px) {
    left: 50%;
    margin-left: -93px;
  }
`;

const Img = styled.img`
  height: 50px;
  margin: 1em 2em;
`;

const Header = () => (
  <HeaderContainer>
    <Overlay />

    <LogoContainer>
      <a href="http://directivegames.com/">
        <Img src={logoImage} />
      </a>
    </LogoContainer>

    <Title>Kamoflage</Title>

    <Divider />

    <ButtonContainer>
      <Button href="https://app.kamo.io">Frontend</Button>

      <Link to="clients" smooth>
        <Button>Client</Button>
      </Link>
    </ButtonContainer>
  </HeaderContainer>
);

export default Header;
