import React, { useRef } from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

import nodeHibernation from './assets/node_hibernation.mp4';

const Container = styled.div`
  width: 100%;
  background: #333;
  /* padding: 2em 0; */
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  color: #ddd;
`;

const StyledVideo = styled.video`
  display: block;
  width: 100%;
  outline: none;
`;

const Video = () => {
  const ref = useRef();

  const onChange = visible => {
    if (!ref || !ref.current) {
      return;
    }

    if (visible) {
      ref.current.play();
    } else {
      ref.current.pause();
    }
  };

  return (
    <Container>
      <Wrapper>
        <VisibilitySensor onChange={onChange} partialVisibility>
          <StyledVideo controls preload="auto" ref={ref}>
            <source src={nodeHibernation} type="video/mp4" />
          </StyledVideo>
        </VisibilitySensor>
      </Wrapper>
    </Container>
  );
};

export default Video;
