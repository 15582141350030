import React from 'react';
import styled from 'styled-components';
import { Cpu } from 'styled-icons/feather';

const Container = styled.div`
  width: 100%;
  background: #222;
  padding: 2em 0;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  color: #ddd;

  > * {
    width: 100%;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 0.5fr) minmax(0, 1.5fr);
  grid-template-rows: minmax(0, 1fr);

  h2 {
    font-size: 2.5em;
    font-weight: 300;
    margin: 0;
    margin-bottom: 0.5em;
  }

  p {
    font-weight: 200;
  }

  svg {
    margin: auto;
  }
`;

const Description = () => {
  return (
    <Container>
      <Wrapper>
        <Content>
          <Cpu size={150} />

          <div>
            <h2>KAMO</h2>

            <p>
              The KAMO system provides plug-and-play persistence, and ownership management of Actors
              and player data across multi-server worlds. It provides the ability to query across
              server boundaries to get portal style views into neighboring areas. It's largely
              transparent to game programmers, but exposes hooks for customization where needed. It
              also allows for light-weight simulations to run on persisted Actors without a live UE4
              server.
            </p>
          </div>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default Description;
