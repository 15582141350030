import React from 'react';
import styled from 'styled-components';

const ButtonStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  cursor: pointer;

  background-color: rgba(110, 30, 15, 0.2);
  transition: all 0.25s ease-in-out;

  svg {
    height: 55px;
    width: 150px;
    fill: none;
    stroke-width: 5;

    .o1 {
      stroke: rgba(228, 70, 37, 0.5);
      transition: all 0.5s ease-in-out;
    }

    .o2 {
      stroke: white;
      stroke-dasharray: 20 420;
      stroke-dashoffset: 20;
      transition: all 1s ease-in-out;
    }
  }

  span {
    position: absolute;
    margin: auto 0;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  :hover {
    background-color: rgba(110, 30, 15, 0.8);

    .o1 {
      stroke: rgba(228, 70, 37, 1);
    }

    .o2 {
      stroke-dashoffset: -420;
    }
  }
`;

const Button = ({ children, onClick, href }) => (
  <ButtonStyles onClick={onClick} as={href ? 'a' : 'div'} href={href}>
    <span>{children}</span>

    <svg>
      <polyline className="o1" points="0 0, 150 0, 150 55, 0 55, 0 0" />
      <polyline className="o2" points="0 0, 150 0, 150 55, 0 55, 0 0" />
    </svg>
  </ButtonStyles>
);

export default Button;
