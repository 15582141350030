import React from 'react';
import styled from 'styled-components';
import { Windows } from 'styled-icons/fa-brands';
import { Element } from 'react-scroll';

const Container = styled.div`
  width: 100%;
  background: #222;
  padding: 2em 0;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  color: #ddd;

  > * {
    width: 100%;
  }
`;

const Headline = styled.div`
  padding-bottom: 1em;

  h2 {
    font-size: 2.5em;
    font-weight: 300;
    margin: 0;
    margin-bottom: 0.5em;
  }

  p {
    font-weight: 200;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: 0 auto;
`;

const Item = styled.a`
  color: #ddd;
  text-decoration: none;
  transition: all 0.25s ease-in-out;

  :hover {
    filter: brightness(0.8);
  }

  h3 {
    font-size: 1.2em;
    font-weight: 300;
    margin: 0;
  }
`;

const iconSize = 100;

const Clients = () => {
  return (
    <Element name="clients">
      <Container>
        <Wrapper>
          <Headline>
            <h2>KAMO Client</h2>

            <p>Download a demo game client and experience KAMO first-hand</p>
          </Headline>

          <Content>
            <Item href="https://kamo.io/builds/client/kamo-client.zip">
              <Windows size={iconSize} />
              <h3>Windows</h3>
            </Item>
          </Content>
        </Wrapper>
      </Container>
    </Element>
  );
};

export default Clients;
